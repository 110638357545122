@import "npm:@fontsource/atkinson-hyperlegible";
@import "npm:@fontsource/league-mono-extended/600.css";
@import "npm:@fontsource/league-mono/600.css";
@import "npm:simpledotcss/simple.css";

:root {
    --sans-font: "Atkinson Hyperlegible", -apple-system, BlinkMacSystemFont,
        "Avenir Next", Avenir, "Nimbus Sans L", Roboto, "Noto Sans", "Segoe UI",
        Arial, Helvetica, "Helvetica Neue", sans-serif;

    --bg: #fff9f2;
    --accent-bg: #ded9d3;
    --text: #191919;
    --text-light: #686664;
    --accent: #83254f;
}

@media (prefers-color-scheme: dark) {
    :root {
        --bg: #191919;
        --accent-bg: #2e2d2c;
        --text: #ded9d3;
        --text-light: #a09c98;
        --accent: #ffab5c;
    }
}
